import React from "react";


export default function Ep(props) {
  return (
    <>
    <div class="mb-0.1 mt-mb-0.1 ml-24 flex justify-center">
      <div
        class="flex items-center text-sm justify-between transition duration-1000 bg-customBg w-1.2 h-1.2  rounded-xl"
      >
        <h5 class="text-base pl-3 text-amber-100">
        {props.ep} - Hin-Anime
        </h5>
        <a href={props.link} target="_blank" rel="noreferrer">
          <button
            class="bg-customBt text-center text-xs h-9 w-auto pl-2 pr-2 text-white mr-3 border-blue-600 border-2 rounded-2xl hover:bg-customHover transition duration-1000"
          >
            Download {props.s}
          </button>
        </a>
      </div>
    </div> 

      
    </>
  );
}
