import Main from "../Structure/Main";
import Ep from "../Structure/Ep";
import Animeover from "../Structure/Animeover";
import Footer from "../components/Footer";

export default function Danda() {
  return (
    <>
 <Main img="https://gkids.com/wp-content/uploads/2024/06/DANDA_Poster_RGB_Digital_EpisodeText-1-702x1024.jpg" name="DAN DA DAN" s="1" e="12" />
 <Ep ep="S1-E1 DAN DA DAN" s="S1-E1" link="https://drive.google.com/file/d/18h576uR9NL7RVi0z6seZ0sUpQk90dclU/view?usp=sharing" />
 
 <Animeover/>
<Footer/>
</>
  )
}
