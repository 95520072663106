import React, { useState } from "react";
import { Link } from "react-router-dom";
import data from "./data";

const SearchBar = () => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);

    if (value) {
      const filteredData = data
        .filter((item) => item.name.toLowerCase().includes(value.toLowerCase()))
        .slice(0, 5);
      setResults(filteredData);
    } else {
      setResults([]);
    }
  };

  return (
    <>
      <div className="">
        {/* Search Bar */}
        <div className="flex h-20 justify-center backdrop-blur rounded-t-lgdis border border-whitegood shadow shadow-black">
          <div className=" mt-4 h-12 w-11/12 md:w-2/3 lg:w-1/2 flex justify-center items-center backdrop-blur-lg rounded-lg border border-gray-300 shadow-md">
            <div className="bg-sky-800 h-12 w-12 rounded-l-lg flex justify-center items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                className="w-6 h-6 fill-sky-500"
              >
                <path d="M 19 3 C 13.488281 3 9 7.488281 9 13 C 9 15.394531 9.839844 17.589844 11.25 19.3125 L 3.28125 27.28125 L 4.71875 28.71875 L 12.6875 20.75 C 14.410156 22.160156 16.605469 23 19 23 C 24.511719 23 29 18.511719 29 13 C 29 7.488281 24.511719 3 19 3 Z M 19 5 C 23.429688 5 27 8.570313 27 13 C 27 17.429688 23.429688 21 19 21 C 14.570313 21 11 17.429688 11 13 C 11 8.570313 14.570313 5 19 5 Z"></path>
              </svg>
            </div>
            <input
              type="text"
              className="w-full h-full pl-4 text-lg rounded-r-lg bg-gray-900 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-sky-500"
              value={query}
              onChange={handleInputChange}
              placeholder="Search anime..."
            />
          </div>
        </div>

        {/* Search Results */}
        {results.length > 0 && (
          <div className="flex justify-center mt-2">
            <div className="w-11/12 md:w-2/3 lg:w-1/2 backdrop-blur-lg rounded-lg border border-gray-300 shadow-md bg-gray-800 text-white">
              <ul className="py-2">
                {results.map((item) => (
                  <li key={item.id} className="px-4 py-2 hover:bg-gray-700">
                    <Link to={item.link}>{item.name}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SearchBar;
