import React from "react";
import { Link } from "react-router-dom";

export default function Home(props) {
  return (
    <>
      <div className="flex justify-center ml-24">
        <div className="h-100 w-100 bg-whitelite flex flex-wrap justify-center items-center  backdrop-blur  shadow  max-md:w-96 max-md:h-112">
          <div className="transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-300">
            <Link to={props.link1}>
             
              <img
                src={props.img1}
                className="h-96 w-auto mb-4 ml-4 border-none rounded-lg max-md:h-60 max-md:ml-0"
                alt={props.name1}
              />

              <div className="-mt-2 ml-4 max-md:-ml-0">
                <p className="text-2xl text-white w-60 max-md:w-40 max-md:text-base">
                  {props.name1}
                </p>
              </div>
            </Link>
          </div>

          {/* 2 row */}

          <div className="transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-300">
            <Link to={props.link2}>
             
              <img
                src={props.img2}
                className="h-96 w-auto mb-4 ml-4 border-none rounded-lg max-md:h-60"
                alt={props.name2}
              />
              <div className="-mt-2 ml-4">
                <p className="text-2xl w-60 text-white max-md:w-40 max-md:text-base">
                  {props.name2}
                </p>
              </div>
            </Link>
          </div>

          {/* 3 row  */}

          <div className="transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-300 max-md:-mt-8">
            <Link to={props.link3}>
            
              <img
                src={props.img3}
                className="h-96 w-auto mb-4 ml-4 border-none rounded-lg max-md:h-60 max-md:ml-0"
                alt={props.name3}
              />
              <div className="-mt-2 ml-4 max-md:-ml-0">
                <p className="text-2xl w-60 text-white max-md:w-40 max-md:text-base">
                  {props.name3}
                </p>
              </div>
            </Link>
          </div>

          {/* 4 row  */}

          <div className="transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-300 max-md:-mt-8">
            <Link to={props.link4}>
              
              <img
                src={props.img4}
                className="h-96 w-auto mb-4 ml-4 border-none rounded-lg max-md:h-60"
                alt={props.name4}
              />
              <div className="-mt-2 ml-4">
                <p className="text-2xl w-60 text-white max-md:w-40 max-md:text-base">
                  {props.name4}
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
