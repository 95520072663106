import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from '@auth0/auth0-react';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  
  <Auth0Provider
    domain="dev-83e3gn4ou6ympnv5.us.auth0.com"
    clientId="mQHj4302MzG9MK6lYsFjJHJObR8E1WjG"
    authorizationParams={{
      redirect_uri: window.location.origin
    }}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
  </Auth0Provider>
);


reportWebVitals();
